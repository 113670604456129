import { BufferSchema, Model, int16, string8, uint64, uint8 } from "@geckos.io/typed-array-buffer-schema";

// schema.js
const playerSchema = BufferSchema.schema('player', {
  id: uint8,
  x: { type: int16, digits: 1 },
  y: { type: int16, digits: 1 }
})

const snapshotSchema = BufferSchema.schema('snapshot', {
  id: { type: string8, length: 6 },
  time: uint64,
  state: { players: [playerSchema] }
})

export const snapshotModel = new Model(snapshotSchema)