import { useEffect, useState } from "react";

const MAXDISTANCE = 10;

export function useTouch() {
  const [angle, setAngle] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [center, setCenter] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const onStart = (e: TouchEvent) => {
      centerX = e.touches[0].clientX;
      centerY = e.touches[0].clientY;
    };

    const onEnd = (e: TouchEvent) => {
      setSpeed(0);
    };

    const onMove = (e: TouchEvent) => {
      const clientX = e.touches[0].clientX;
      const clientY = e.touches[0].clientY;
      const angle = Math.atan2(clientY - centerY, clientX - centerX);
      const snapangle = (Math.round((angle * 8) / Math.PI) / 8) * Math.PI;
      const distance = Math.hypot(clientY - centerY, clientX - centerX);
      let speed = Math.min(MAXDISTANCE, distance);
      if (distance >= MAXDISTANCE) {
        centerX = centerX + (distance - MAXDISTANCE) * Math.cos(angle);
        centerY = centerY + (distance - MAXDISTANCE) * Math.sin(angle);
      }
      setAngle(snapangle);
      setSpeed(speed);
      setCenter({ x: centerX, y: centerY });
    };
    const el = document;
    let centerX: number;
    let centerY: number;
    el.addEventListener("touchstart", onStart);
    el.addEventListener("touchmove", onMove);
    el.addEventListener("touchend", onEnd);
    el.addEventListener("touchcancel", onEnd);
    return () => {
      el.removeEventListener("touchstart", onStart);
      el.removeEventListener("touchmove", onMove);
      el.removeEventListener("touchend", onEnd);
      el.removeEventListener("touchcancel", onEnd);
    };
  }, []);

  return { angle, speed, center };
}
