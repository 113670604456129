import type * as Party from "partykit/server";
import { SnapshotInterpolation } from '@geckos.io/snapshot-interpolation'
import { snapshotModel } from "./models";

export const serverFPS = 60;

export default class Server implements Party.Server {
  count = 0;
  SI = new SnapshotInterpolation()
  worldState = {
    players: [
      { id: '0', x: 17.5, y: -1.1 },
      { id: '1', x: 5.8, y: 18.9 }
    ]
  }

  constructor(readonly room: Party.Room) {}

  onConnect(conn: Party.Connection, ctx: Party.ConnectionContext) {
    // A websocket just connected!
    console.log(
      `Connected:
  id: ${conn.id}
  room: ${this.room.id}
  url: ${new URL(ctx.request.url).pathname}`
    );

    // send the current count to the new client
    conn.send(this.count.toString());
  }

  onMessage(message: string, sender: Party.Connection) {
    // let's log the message
    console.log(`connection ${sender.id} sent message: ${message}`);
    // we could use a more sophisticated protocol here, such as JSON
    // in the message data, but for simplicity we just use a string
    if (message === "increment") {
      this.increment();
    }else if (message === "update") {
      this.update();
    }
  }

  onRequest(req: Party.Request) {
    // response to any HTTP request (any method, any path) with the current
    // count. This allows us to use SSR to give components an initial value

    // if the request is a POST, increment the count
    if (req.method === "POST") {
      this.increment();
    }

    return new Response(this.count.toString());
  }

  increment() {
    this.count = (this.count + 1) % 100;
    // broadcast the new count to all clients
    this.room.broadcast(this.count.toString(), []);
  }

  update() {
    // create a snapshot of the current world
    const snapshot = this.SI.snapshot.create(this.worldState)
    
    const buffer = snapshotModel.toBuffer(snapshot)
  
    // send the snapshot to the client (using geckos.io or any other library)
    this.room.broadcast(buffer);
  }
}

Server satisfies Party.Worker;
