import "./styles.css";
import { createRoot } from "react-dom/client";
import Counter from "./components/Counter";
import { useTouch } from "./components/useTouch";
import { useEffect, useRef, useState } from "react";
import usePartySocket from "partysocket/react";
import { SnapshotInterpolation } from "@geckos.io/snapshot-interpolation";
import { serverFPS } from "../party/server";
import { snapshotModel } from "../party/models";

// initialize the library (add your server's fps)
const SI = new SnapshotInterpolation(serverFPS);

function App() {
  const { angle, speed, center } = useTouch();

  const [pos, setPos] = useState({
    x: window.outerWidth / 2,
    y: window.outerHeight / 2,
    speedX: 0,
    speedY: 0,
  });

  const socket = usePartySocket({
    // host defaults to the current URL if not set
    //host: process.env.PARTYKIT_HOST,
    // we could use any room name here
    room: "example-room",
    onMessage(evt) {
      const snapshot = snapshotModel.fromBuffer(evt.data);
      SI.snapshot.add(snapshot);
    },
  });

  const requestRef = useRef<number>();

  useEffect(() => {
    const animate = (time: number) => {
      // calculate the interpolation for the parameters x and y and return the snapshot
      const snapshot = SI.calcInterpolation("x y"); // [deep: string] as optional second parameter

      if (snapshot) {
        // access your state
        const { state } = snapshot;

        // apply the interpolated values to you game objects
        const { id, x, y } = state[0];
        if (id === "0") {
          setPos((prev) => {
            return {
              speedX: prev.speedX,
              speedY: prev.speedY,
              x: x as number,
              y: y as number,
            };
          });
        }
      }
      setPos((prev) => {
        const newSpeeds = {
          x: (prev.speedX + (speed / 5) * Math.cos(angle)) * 0.6,
          y: (prev.speedY + (speed / 5) * Math.sin(angle)) * 0.6,
        };
        return {
          speedX: newSpeeds.x,
          speedY: newSpeeds.y,
          x: prev.x + newSpeeds.x,
          y: prev.y + newSpeeds.y,
        };
      });
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, [speed, angle]);

  return (
    <main>
      <h1>🎈 Welcome to PartyKit!</h1>
      <p>
        This is the React starter. (
        <a href="https://github.com/partykit/templates/tree/main/templates/react">
          README on GitHub.
        </a>
        )
      </p>
      <p>Find your way around:</p>
      <ul>
        <li>
          PartyKit server: <code>party/server.ts</code>
        </li>
        <li>
          Client entrypoint: <code>app/client.tsx</code>
        </li>
        <li>
          The Counter component: <code>app/components/Counter.tsx</code>
        </li>
      </ul>
      <p>
        Read more: <a href="https://docs.partykit.io">PartyKit docs</a>
      </p>
      <p>{angle}</p>
      <p>{speed}</p>
      <div
        style={{
          background: "red",
          transform: `translate(${pos.x}px, ${pos.y}px)`,
          position: "fixed",
          left: 0,
          top: 0,
          width: 10,
          height: 10,
        }}
      />
      <div
        style={{
          background: "blue",
          transform: `translate(${center.x}px, ${center.y}px)`,
          position: "fixed",
          left: 0,
          top: 0,
          width: 5,
          height: 5,
        }}
      />
      <Counter />
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
